<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">素材工单历史</span>
      </div>
    </template>
    <el-form ref="historyFormRef" :inline="true" :model="historyForm" style="padding-top: 15px;">
      <el-form-item label="素材工单编号" prop="num">
        <el-input placeholder="请输入工单编号" size="small" v-model="historyForm.num"></el-input>
      </el-form-item>
      <el-form-item label="素材标题" prop="title">
        <el-input placeholder="请输入素材标题" size="small" v-model="historyForm.title"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="historyForm.type" placeholder="请选择类型" size="small">
          <el-option :label="item.title" :value="item.id" v-for="item in this.TypeOption" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="historyForm.status" placeholder="请选择状态" size="small">
          <el-option label="已提交" value="0"></el-option>
          <el-option label="制作中" value="1"></el-option>
          <el-option label="已完成" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 14px;">
        <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="historyList"
      style="width: 100%"
      :header-cell-style="{
        background: 'var(--el-table-row-hover-background-color)'
      }"
    >
      <el-table-column property="work_no" label="素材工单编号" fixed />
      <el-table-column property="title" label="素材标题" />
      <el-table-column property="type" label="素材类型" />
      <el-table-column label="工单状态" min-width="130">
        <template #default="{row}">
          <el-tag type="success" v-if="row.status == 1">已完成</el-tag>
          <el-tag type="warning" v-else-if="row.status == 0">已提交</el-tag>
          <el-tag type="warning" v-else>制作中</el-tag>
        </template>
      </el-table-column>
      <el-table-column property="create_at" label="提交时间" />
      <el-table-column property="end_at" label="解决时间" />
      <!-- <el-table-column property="reason" label="备注" min-width="200">
        <template #default="{row}">
          <span>
            {{ row.reason }}
          </span>
          <div v-if="row.url != ''">
            <el-image style="width: 50px; height: 50px" :src="row.url" :preview-src-list="row.urls" @click.stop="">
            </el-image>
          </div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="操作" min-width="130" fixed="right">
        <template #default="{row}">
          <el-button type="text" v-show="row.status == 1" @click="goDownLoad(row.end_img)">下载</el-button>
        </template>
      </el-table-column> -->
      <template #empty>
        <img src="@/assets/empty.gif" alt="" style="width:400px;height:400px" />
      </template>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[5, 10]"
      :page-size="queryInfo.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
    >
    </el-pagination>
  </el-card>
</template>

<script>
export default {
  name: 'FodderHistory',
  data() {
    return {
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      TypeOption: [],
      historyForm: {
        num: '',
        title: '',
        type: '',
        status: ''
      },
      historyList: [],
      total: 0
    }
  },
  mounted() {
    this.getType()
    this.getHistoryList()
  },
  methods: {
    async getType() {
      const { data: res } = await this.$http.post('material/set_material_type')

      this.TypeOption = res.data
    },
    async getHistoryList() {
      const { data: res } = await this.$http.post('material/material_work_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        work_no: this.historyForm.num,
        title: this.historyForm.title,
        type: this.historyForm.type,
        status: this.historyForm.status
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        return this.$router.push('/login')
      }
      this.historyList = res.data
      this.total = res.total
    },
    queryFormBtn() {
      this.getHistoryList()
    },
    resetFormBtn() {
      this.$refs.historyFormRef.resetFields()
      this.getHistoryList()
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getHistoryList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getHistoryList()
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
