<template>
  <p class="Tetx" style="padding-left: 16px;">请选择您想要制作素材的商品图</p>
  <div class="templateList">
    <div
      class="templateListItem"
      v-for="item in material_list"
      :key="item.id"
      @mouseenter="mouseenter(item.id)"
      @mouseleave="mouseleave(item.id)"
    >
      <div class="imgBox" @click="selectTem(item.id)">
        <el-image :src="`${item.img}`" fit="cover" v-loading="loading">
          <template #placeholder>
            <span class="Text" style="color:#2b82ff;">正在加载中...</span>
          </template>
        </el-image>
        <div class="selectMask" v-show="selectList.indexOf(item.id) > -1">
          <img src="../../../assets/iconImg/icon_select.png" alt="" class="icon_select" />
        </div>
      </div>
      <div class="replaceBtn">
        <el-button type="primary" size="small" @click="openImgCut(item.id)" v-show="replaceBtn == item.id">
          <i class="iconfont icon-ylreplace"></i>
          替换图片
        </el-button>
      </div>
    </div>
  </div>
  <div class="templateRadio">
    <el-radio-group v-model="templateRadio" @change="templateRadioChange">
      <el-radio label="1" size="large">使用统一模板</el-radio>
      <el-radio label="2" size="large">使用多种素材模板</el-radio>
    </el-radio-group>
  </div>
  <div class="btnBox">
    <el-button type="primary" @click="makeMaterial">制作素材</el-button>
  </div>

  <!-- 裁剪插件弹框 -->
  <el-dialog
    v-model="imgCutDialogVisible"
    @close="imgCutClose"
    title="上传图片"
    width="800px"
    custom-class="imgCutDialog"
  >
    <div style="width: 100%;height:400px;">
      <vueCropper
        ref="cropper"
        :img="CropperOption.img"
        :fixed="true"
        :outputSize="CropperOption.outputSize"
        :autoCrop="CropperOption.autoCrop"
        :full="CropperOption.full"
        @realTime="realTime"
      ></vueCropper>
      <div class="footer-btn">
        <el-upload
          ref="imgCutUpload"
          class="imgCutUpload"
          accept=".jpeg,.png,.jpg"
          action=""
          :before-upload="beforeImgCutUpload"
          :limit="1"
        >
          <el-button type="primary" size="small">上传图片</el-button>
          <span class="Text" style="margin-left:16px;color: #F56C6C;">支持格式(.jpeg, .png, .jpg)</span>
        </el-upload>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmImgBtn('blob')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  name: 'MaterialSelect',
  props: ['urlId'],
  data() {
    return {
      loading: false,
      isSelectId: 0,
      selectId: 0,
      saveImgPath: '',
      material_list: [],
      selectList: [],
      replaceBtn: 0,
      imgCutDialogVisible: false,
      // 上传的图
      clientImage: {},
      CropperOption: {
        img: '',
        size: 1,
        full: true,
        outputSize: 1,
        outputType: 'png',
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 800,
        autoCropHeight: 800,
        centerBox: false,
        high: true,
        max: 99999
      },
      templateRadio: '1'
    }
  },
  mounted() {
    this.getTemplate()
  },
  methods: {
    async getTemplate() {
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在加载中，请稍后...'
      })
      this.loading = true
      this.$http
        .post('material/show_img', {
          type: 1,
          id: this.urlId
        })
        .then(({ data: res }) => {
          if (res.code == 300) {
            return this.$router.push('/login')
          }
          this.material_list = res.data
        })
        .finally(() => {
          loading.close()
          this.loading = false
        })
    },
    selectTem(id) {
      if (this.selectList.indexOf(id) == -1) {
        this.selectList.push(id)
      } else {
        this.selectList.filter(item => {
          if (item == id) {
            let index = this.selectList.indexOf(item)
            this.selectList.splice(index, 1)
          }
        })
      }
    },
    mouseenter(id) {
      this.material_list.forEach(item => {
        if (item.id == id) {
          this.replaceBtn = id
        }
      })
    },
    mouseleave() {
      this.replaceBtn = 0
    },
    openImgCut(id) {
      this.selectId = id
      this.imgCutDialogVisible = true
    },
    imgCutClose() {
      this.CropperOption.img = ''
    },
    realTime(data) {
      this.$refs.cropper.getCropBlob(data => {
        this.clientImage = new FormData()
        this.clientImage.append('file', data)
      })
    },
    // 上传自己图片前
    beforeImgCutUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const suffix4 = img == 'JPG'
      const suffix5 = img == 'PNG'
      const suffix6 = img == 'JPEG'
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error('仅支持发送图片！')
        return false
      }
      const reader = new FileReader()
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        // 获取到需要剪裁的图片 展示到剪裁框中
        this.CropperOption.img = reader.result
      }
      return false
    },
    // 确定上传自己的图片
    confirmImgBtn(type) {
      this.imgCutDialogVisible = false
      this.loading = true
      this.$http
        .post('material/uploadfile_material', this.clientImage)
        .then(({ data: res }) => {
          if (res.code == 100) {
            return this.$message.error('上传失败，请重试')
          }
          this.saveImgPath = res.path
          this.$http.post('material/save_img', {
            path: res.path,
            id: this.selectId
          })
          if (res.code == 100) {
            return this.$message.error('替换失败')
          }
          this.getTemplate()
        })
        .finally(() => {
          this.loading = false
        })
    },
    templateRadioChange() {
      // console.log(this.templateRadio)
    },
    async makeMaterial() {
      if (this.selectList == '') {
        return this.$message.warning('请选择您需要制作的图片')
      }
      const { data: res } = await this.$http.post('material/set_material', {
        type: this.templateRadio,
        p_id: this.urlId,
        ids: this.selectList,
        s_type: 0
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      this.$router.push({
        name: 'MaterialResult',
        query: {
          id: this.urlId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.templateList {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 16px;
  .templateListItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 55px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    .imgBox {
      position: relative;
      width: 315px;
      height: 315px;
      margin-bottom: 15px;
      .selectMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 1;
        .icon_select {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 30px;
          height: 30px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    .replaceBtn {
      height: 40px;
    }
  }
}
.templateRadio {
  text-align: center;
  margin-bottom: 30px;
}
.btnBox {
  width: 100%;
  text-align: center;
}
.imgCutUpload {
  margin-top: 16px;
}
@media screen and (max-width: 1550px) {
  .templateList .templateListItem .imgBox {
    width: 310px;
    height: 310px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 290px;
    height: 290px;
  }
}
@media screen and (max-width: 1440px) {
  .templateList .templateListItem .imgBox {
    width: 280px;
    height: 280px;
  }
}
</style>
