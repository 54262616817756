<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">申请制作</span>
      </div>
    </template>
    <el-row :gutter="20">
      <el-col :span="15">
        <el-form
          ref="fodderApplyRef"
          :model="fodderApplyForm"
          :rules="fodderApplyRule"
          label-width="120px"
          class="fodderApplyForm"
        >
          <el-form-item label="您的选择">
            <div class="title">{{ ApplyData.title }}</div>
            <ul class="content">
              <li class="price">
                <span v-if="ApplyData.activity_price == 0"
                  >价格：<i>{{ ApplyData.original_price }}</i
                  >美元/{{ ApplyData.unit }}</span
                >
                <span v-else>
                  <span
                    >活动价：<i>{{ ApplyData.activity_price }}</i
                    >美元/{{ ApplyData.unit }}</span
                  >
                  <span class="originalPrice">原价：{{ ApplyData.original_price }}美元/{{ ApplyData.unit }}</span>
                </span>
              </li>
              <li class="rule">
                {{ ApplyData.condition }}
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="素材标题" prop="title">
            <el-input size="small" placeholder="请输入素材标题" v-model="fodderApplyForm.title" clearable></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input size="small" placeholder="请输入联系电话" v-model="fodderApplyForm.phone" clearable></el-input>
          </el-form-item>
          <el-form-item label="素材描述" prop="describe">
            <el-input
              size="small"
              placeholder="请输入您对素材的期望及需求"
              type="textarea"
              v-model.lazy="fodderApplyForm.describe"
            ></el-input>
          </el-form-item>
          <el-form-item label="示例素材" prop="example">
            <div class="uploadBox">
              <el-upload
                v-model:file-list="fodderApplyForm.fileList"
                class="upload-fodder"
                :action="uploadURL"
                multiple
                accept=".rar,.zip"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                <el-button size="small">
                  <img src="@/assets/images/upload_icon.png" alt="" />
                  上传文件
                </el-button>
              </el-upload>
              <span class="uploadTips">如您有偏好的示例素材，请上传</span>
            </div>
            <span class="uploadTips">支持扩展名：.rar, .zip</span>
          </el-form-item>

          <el-form-item label="基础素材提供" prop="fileList1">
            <div class="uploadBox">
              <el-upload
                v-model:file-list="fodderApplyForm.fileList1"
                class="upload-fodder1"
                :action="uploadURL"
                multiple
                accept=".rar,.zip"
                :on-remove="handleRemove1"
                :on-success="handleSuccess1"
                :on-change="handleChange1"
                :before-upload="beforeUpload1"
              >
                <el-button size="small">
                  <img src="@/assets/images/upload_icon.png" alt="" />
                  上传文件
                </el-button>
              </el-upload>
              <span class="uploadTips">建议上传多张商品图，以便投放效果更佳</span>
            </div>
            <span class="uploadTips">支持扩展名：.rar, .zip</span>
          </el-form-item>
          <el-form-item>
            <el-row align="middle" style="margin-top: 15px">
              <el-checkbox v-model="agreeFodder" label="" style="margin-right: 5px"></el-checkbox>
              <div class="agreeBox">
                <span class="Text">勾选后，即同意商务人员将会根据您的需求进行具体沟通</span>
                <!-- <el-link href="#" target="_blank" :underline="false">
                  <span class="brandColor" style="font-size: 14px">素材工单定制化协议、服务条款、隐私政策</span>
                </el-link> -->
              </div>
            </el-row>
          </el-form-item>
          <el-form-item>
            <div
              style="width: 90%;display: flex;justify-content: end;"
            >
              <el-button size="small" @click="getBack">取消</el-button>
              <el-button type="primary" size="small" :disabled="agreeFodder ? false : true" @click="applyBtn"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="9" style="display:flex;align-items:center;justify-content: center;">
        <div class="right">
          <img src="@/assets/images/fodderApplyBg.png" alt="" />
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import base from "../../request/base";
export default {
  name: 'FodderApply',
  props: ['id'],
  data() {
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入有效的电话号码'))
    }
    var checkUpload = (rule, value, cb) => {
      if (this.fodderApplyForm.fileList1.length > 0) {
        return cb()
      }
      cb(new Error('请上传商品图'))
    }
    return {
      base: base,
      ApplyData: {},
      fodderApplyForm: {
        title: '',
        phone: '',
        describe: '',
        fileList: [],
        fileList1: [],
        path: [],
        path1: []
      },
      fodderApplyRule: {
        title: [{ required: true, message: '请输入素材标题', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        describe: [{ required: true, message: '请输入您对素材的期望及需求', trigger: 'blur' }],
        fileList1: [{ validator: checkUpload, required: true, trigger: 'change' }]
      },
      // 图片上传的地址
      uploadURL: `${base.admin_url}/cloud/file/uploadfile_material_work`,
      agreeFodder: false
    }
  },
  mounted() {
    this.getApplyData()
  },
  methods: {
    async getApplyData() {
      if (this.id == undefined) {
        this.$router.push('/fodderWork')
      }
      const { data: res } = await this.$http.post('material/get_material_work', {
        id: this.id
      })
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        return this.$router.push('/login')
      }
      this.ApplyData = res.data
    },
    // 移除文件
    handleRemove(file) {
      let index = this.fodderApplyForm.path.findIndex(item => item == file.response.path)
      this.fodderApplyForm.path.splice(index, 1)
      if (this.fodderApplyForm.path.length == 0) {
        this.fodderApplyForm.fileList = []
      }
    },
    handleRemove1(file) {
      let index = this.fodderApplyForm.path1.findIndex(item => item == file.response.path)
      this.fodderApplyForm.path1.splice(index, 1)
      if (this.fodderApplyForm.path.length == 0) {
        this.fodderApplyForm.fileList1 = []
      }
    },
    // 文件上传成功的回调
    handleSuccess(response) {
      this.fodderApplyForm.path.push(response.path)
    },
    handleSuccess1(response) {
      this.fodderApplyForm.path1.push(response.path)
    },
    beforeUpload(file) {
      var uploadFile = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix1 = uploadFile === 'zip'
      const suffix2 = uploadFile === 'rar'
      // const isLt1M = file.size / 1024 / 1024 < 1
      if (!suffix1 && !suffix2) {
        this.$message.error('仅支持上传文件压缩包！')
        return false
      }
      // 可以限制文件的大小
      // if (!isLt1M) {
      //  return this.$message.error('上传图片大小不能超过 1MB!')
      // }
    },
    beforeUpload1(file) {
      var uploadFile = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix1 = uploadFile === 'zip'
      const suffix2 = uploadFile === 'rar'
      // const isLt1M = file.size / 1024 / 1024 < 1
      if (!suffix1 && !suffix2) {
        this.$message.error('仅支持上传文件压缩包！')
        return false
      }
      // 可以限制文件的大小
      // if (!isLt1M) {
      //   this.$message.error('上传图片大小不能超过 1MB!')
      // }
    },
    handleChange1(file1, fileList1) {
      this.fodderApplyForm.fileList1 = fileList1
      if (this.fodderApplyForm.fileList1.length > 0) {
        this.$refs.fodderApplyRef.validateField('fileList1')
      }
    },
    getBack() {
      this.$router.push('/fodderWork')
    },
    applyBtn() {
      this.$refs.fodderApplyRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('material/set_material_work', {
          id: this.id,
          title: this.fodderApplyForm.title,
          phone: this.fodderApplyForm.phone,
          describe: this.fodderApplyForm.describe,
          path: this.fodderApplyForm.path,
          path1: this.fodderApplyForm.path1
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          return this.$router.push('/login')
        }
        if (res.code == 200) {
          this.$message.success(`${res.msg}`)
          this.fodderApplyForm.path = []
          this.fodderApplyForm.path1 = []
          this.$refs.fodderApplyRef.resetFields()
          this.$router.push('/fodderHistory')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep(.el-icon-close-tip) {
  font-size: 0;
  display: none;
}
.fodderApplyForm {
  .el-input,
  .el-textarea {
    width: 90%;
  }
  .el-button img {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
  }
  .uploadBox {
    display: flex;
  }

  .uploadTips {
    font-size: 14px;
    color: #8c8c8c;
    margin-left: 8px;
  }
  .title {
    font-size: 18px;
    color: #2b82ff;
  }
  .content {
    display: flex;
    align-items: center;
    padding-left: 18px;
    li {
      font-size: 14px;
      list-style: disc;
    }
    .price {
      color: #2b82ff;
      margin-right: 16px;
      span {
        margin-right: 16px;
        i {
          font-size: 32px;
        }
      }
      .originalPrice {
        text-decoration: line-through;
      }
    }
    .rule {
      color: #8c8c8c;
      padding-top: 6px;
    }
  }
}
.right {
  width: 440px;
  height: 350px;
  img {
    width: 100%;
  }
}
</style>
