<template>
  <el-row justify="space-between">
    <el-col :span="10">
      <el-form ref="urlFormRef" :rules="urlFormRules" :model="urlForm">
        <el-form-item label="商品链接" label-width="80px" prop="shopUrl" :error="errMsg" style="margin-bottom: 60px;">
          <el-input v-model="urlForm.shopUrl" placeholder="请输入您的商品详情页链接" clearable @clear="inputClear">
            <template #append>
              <el-button type="primary" :loading="btnLoading" @click="importURL">{{ btnText }}</el-button>
            </template>
          </el-input>
          <p class="Text tips">
            注：仅支持shoplazza店铺的产品详情页链接
          </p>
        </el-form-item>
        <el-form-item label="素材形式" label-width="80px">
          <el-radio-group v-model="urlForm.typeRadio">
            <div class="materialType">
              <div class="typeItem">
                <img
                  style="width: 100px; height: 100px;box-shadow: var(--el-box-shadow-light);"
                  src="../../../assets/images/demo_img.png"
                />
                <el-radio :label="1">图片素材</el-radio>
              </div>
              <!-- <div class="typeItem">
                <el-image style="width: 200px; height: 100px" :src="url" fit="fill" />
                <el-radio :label="2">视频素材</el-radio>
              </div> -->
            </div>
          </el-radio-group>
        </el-form-item>
        <h3 class="SubHead title">规格设置</h3>
        <el-form-item label="折扣" label-width="80px" prop="discount">
          <el-input v-model="urlForm.discount" placeholder="请输入折扣" clearable />
        </el-form-item>
        <el-form-item label="原价" label-width="80px" prop="price_selling">
          <el-input v-model="urlForm.price_selling" placeholder="请输入原价" clearable />
        </el-form-item>
        <el-form-item label="优惠价" label-width="80px" prop="price_market">
          <el-input v-model="urlForm.price_market" placeholder="请输入优惠价" clearable />
        </el-form-item>
        <el-form-item label="货币符号" label-width="80px" prop="currency">
          <el-input v-model="urlForm.currency" placeholder="请输入货币符号" clearable />
        </el-form-item>
        <el-form-item label="图片尺寸" label-width="80px">
          <el-radio-group v-model="urlForm.sizeRadio">
            <div class="materialType">
              <div class="typeItem">
                <img style="width: 75px; height: 115px" src="../../../assets/images/demo_1x1.png" />
                <el-radio :label="1">1:1</el-radio>
              </div>
              <!-- <div class="typeItem">
                <el-image style="width: 200px; height: 100px" :src="url" fit="fill" />
                <el-radio :label="2">视频素材</el-radio>
              </div> -->
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="text-align:center;">
          <el-button type="primary" @click="goNextStep">下一步</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="10">
      <div class="imgBox">
        <img style="width: 450px; height: 360px" src="../../../assets/images/fodderApplyBg.png" />
        <div class="guideBox">
          <div class="title">
            只需简单三步，自动生成您需要的素材：
          </div>
          <div class="content">
            1.输入您的商品页网址链接，点击导入，系统会自动获取您的商品图片<br />
            2.（可选）输入规格，进行素材自定义设置<br />
            3.点击下一步后选择图片，自动生成广告素材
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'MaterialForm',
  props: ['queryId'],
  data() {
    return {
      btnLoading: false,
      btnText: '导入',
      urlId: 0,
      isSuccess: 0,
      urlForm: {
        shopUrl: '',
        typeRadio: 1,
        discount: '',
        price_selling: '',
        price_market: '',
        currency: '',
        sizeRadio: 1
      },
      urlFormRules: {
        shopUrl: [{ required: true, message: '请输入店铺商品详情页的网址', trigger: 'blur' }]
      },
      errMsg: ''
    }
  },
  mounted() {
    if (this.queryId != undefined) {
      this.getEditTem()
    }
  },
  methods: {
    async getEditTem() {
      const { data: res } = await this.$http.post('material/edit_tem', {
        id: this.queryId
      })
      this.urlForm.shopUrl = res.data.url
      this.urlForm.discount = res.data.discount
      this.urlForm.price_selling = res.data.original_price
      this.urlForm.price_market = res.data.special_price
      this.urlForm.currency = res.data.currency
    },
    goNextStep() {
      this.$refs.urlFormRef.validate(async valid => {
        if (!valid) return
        if (this.isSuccess == 0 && this.urlId == 0) {
          return this.$message.error('尚未导入成功，请稍等')
        }
        const { data: res } = await this.$http.post('material/show_img', {
          type: 0,
          id: this.urlId,
          discount: this.urlForm.discount,
          original_price: this.urlForm.price_selling,
          special_price: this.urlForm.price_market,
          currency: this.urlForm.currency
        })
        if (res.code == 300) {
          return this.$router.push('/login')
        }
        this.$router.push({
          name: 'MaterialSelect',
          query: {
            id: this.urlId
          }
        })
      })
    },
    importURL() {
      if (this.urlForm.shopUrl == '') {
        return (this.errMsg = `请输入店铺商品详情页的网址`)
      }
      this.btnLoading = true
      this.btnText = '导入中...'
      this.$http
        .post('material/grab_image', {
          id: this.urlId,
          url: this.urlForm.shopUrl
        })
        .then(({ data: res }) => {
          if (res.code == 100) {
            return (this.errMsg = `${res.msg}`)
          }
          this.urlId = res.id
          this.$message.success('导入成功，请点击下一步')
          this.isSuccess = 1
        })
        .finally(() => {
          this.btnLoading = false
          this.btnText = '导入'
        })
    },
    inputClear() {
      this.btnLoading = false
      this.btnText = '导入'
      this.errMsg = ''
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  align-items: center;
}
.imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .guideBox {
    margin-top: 30px;
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #262626;
      margin-bottom: 8px;
    }
    .content {
      font-size: 14px;
      color: #595959;
      line-height: 24px;
    }
  }
}
.tips {
  margin: 0;
  position: absolute;
  top: auto;
  padding-top: 5px;
}
.materialType {
  display: flex;
  .typeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
}
.SubHead.title {
  margin-bottom: 30px;
}
</style>
