<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">素材工单</span>
        <router-link to="/fodderHistory"><span class="LinkText">素材工单历史</span></router-link>
      </div>
    </template>
    <div class="work_card">
      <div class="card_item" v-for="item in FodderWorkData" :key="item.id">
        <div class="corner" v-if="item.member == 1">
          <img src="@/assets/images/member_corne.png" alt="" />
        </div>
        <div class="explain_content">
          <div class="left">
            <h2>{{ item.title }}</h2>
            <ul>
              <li>{{ item.describe }}</li>
              <li>{{ item.condition }}</li>
            </ul>
          </div>
          <div class="right">
            <img :src="`${base.admin_url}/${item.img}`" alt="" />
          </div>
        </div>

        <div class="apply_content">
          <img src="../../assets/images/materialBtn.png" alt="" class="materialBtn" />
          <ul class="price_content">
            <li v-if="item.member == 0">
              <span v-if="item.activity_price == 0"
                >价格：<i>{{ item.original_price }}</i
                >美元/{{ item.unit }}</span
              >
              <span v-else>
                <span
                  >活动价：<i>{{ item.activity_price }}</i
                  >美元/{{ item.unit }}</span
                >
                <span class="originalPrice">原价：{{ item.original_price }}美元/{{ item.unit }}</span>
              </span>
            </li>
            <li v-else>
              <span
                >免费次数：<i>{{ item.num }}</i
                >次</span
              >
            </li>
          </ul>
          <el-button type="text" class="applyBtn" @click="applyBtn(item.id)" v-if="item.member == 0"
            >申请制作</el-button
          >
          <el-button type="text" class="applyBtn" @click="applyBtn(item.id)" :disabled="item.num == 0" v-else
            >申请制作</el-button
          >
        </div>
      </div>
      <div class="card_item" style="display:none;"></div>
    </div>
  </el-card>
</template>

<script>
import base from '../../request/base'
export default {
  name: 'FodderWork',
  data() {
    return {
      base: base,
      FodderWorkData: []
    }
  },
  mounted() {
    this.getFodderWorkData()
  },
  methods: {
    async getFodderWorkData() {
      const { data: res } = await this.$http.post('material/material_work')
      if (res.code == 100) {
        return this.$message.error(`${res.msg}`)
      }
      if (res.code == 300) {
        return this.$router.push('/login')
      }
      this.FodderWorkData = res.data
    },
    applyBtn(id) {
      console.log(id)
      this.$router.push({
        name: 'FodderApply',
        query: {
          applyid: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .card_item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 285px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    // margin-right: 43px;
    margin-bottom: 34px;
    .corner {
      position: absolute;
      left: -10px;
      top: -10px;
      width: 80px;
      height: 80px;
      img {
        width: 100%;
      }
    }
    .explain_content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 200px;
      height: 75%;
      padding: 24px;
      box-sizing: border-box;
      .left {
        width: 65%;
        border-right: 1px solid #f0f0f0;
        padding-right: 16px;
        h2 {
          text-align: center;
          margin: 0 0 32px;
        }
        ul {
          margin-left: 24px;
          li {
            font-size: 14px;
            color: #595959;
            line-height: 24px;
            list-style: disc;
            margin-bottom: 24px;
          }
        }
      }
      .right {
        width: 27%;
        text-align: center;
        img {
          width: 134px;
          height: auto;
        }
      }
    }
    .apply_content {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 88px;
      height: 25%;
      .materialBtn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .price_content {
        font-size: 14px;
        color: #2b82ff;
        margin-left: 48px;
        z-index: 1;
        li {
          list-style: disc;
          span {
            margin-right: 16px;
          }
          span.originalPrice {
            text-decoration: line-through;
          }
          i {
            font-size: 32px;
            font-weight: bold;
          }
        }
      }
      .applyBtn {
        position: absolute;
        right: 9%;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .work_card .card_item {
    height: 252px;
  }
  .work_card .card_item .explain_content .left ul li {
    margin-bottom: 16px;
  }
  .work_card .card_item .explain_content .right img {
    width: 120px;
    height: auto;
  }
  .work_card .card_item .apply_content .applyBtn {
    right: 25px;
  }
}
</style>
