<template>
    <div class="container">
        <img src="@/assets/images/base/noCompanyAttest/company-attest.svg" alt="" />
        <div class="title">您还没有进行企业认证</div>
        <div class="tip">企业认证后，Facebook、TikTok、Microsoft、Google等主流海外广告账户一键开户，下户后随时随地自助充值开始推广，即刻开启海外营销！</div>
        <div class="button" @click="$router.push('/clientInfo')">企业认证</div>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.container {
    width: 100%;
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 100px;
    background-color: #fff;
}

.title {
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #262626;
    margin-bottom: 8px;
}

.tip {
    width: 520px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #595959;
    margin-bottom: 20px;
}

.button {
    width: 88px;
    height: 32px;
    background: #2B82FF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
}
</style>